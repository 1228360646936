import React from "react";

const Vission = () => {
  return (
    <div>
      {/*============== Page Banner Start ==============*/}
      <div
        className="page-banner-simple bg-secondary py-50"
        style={{
          backgroundImage: "url(assets/images/background/bg-1.png)",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          backgroundSize: "cover",
          marginTop: 100,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <h3 className="banner-title text-white">Our Vision</h3>
              <span className="banner-tagline font-medium text-white">
                We make strategies, design &amp; development to create valuable
                products.
              </span>
            </div>
          </div>
        </div>
      </div>
      {/*============== Page Banner End ==============*/}
      {/*============== Innter Page Content Start ==============*/}
      <div className="full-row">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="bg-white rounded border p-30">
                <h4 className="mb-3">Mission Statement</h4>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis
                  mollis et sem sed sollicitudin. Donec non odio neque. Aliquam
                  hendrerit sollicitudin purus, quis rutrum mi accumsan nec.
                  Quisque bibendum orci ac nibh facilisis, at malesuada orci
                  congue. Nullam tempus sollicitudin cursus. Ut et adipiscing
                  erat. Curabitur this is a text link libero tempus congue.
                </p>
                <p>
                  Posuere ante tincidunt sed porta senectus fusce interdum.
                  Dictum class semper Ornare venenatis habitant sodales.
                  Venenatis volutpat torquent. Curae; suspendisse ligula
                  suscipit magna. Interdum dapibus in nulla mollis velit taciti
                  mollis gravida torquent lacus lobortis volutpat elementum leo
                  pharetra. Hac congue consequat ante laoreet consectetuer. Cum.
                  Nulla nisl purus a. Et felis platea ligula cubilia massa
                  ultrices leo iaculis porttitor.
                </p>
                <h4 className="mb-3">Property Construction Terms</h4>
                <p>
                  Dolor nunc accumsan. Nostra praesent elit a lacus pharetra.
                  Elit gravida, scelerisque fringilla netus euismod augue a
                  vitae enim erat urna sit, rhoncus. Sociis ligula praesent.
                  Tempus dui taciti integer, convallis Senectus facilisi
                  molestie. Augue libero tincidunt volutpat. Ante hendrerit
                  class etiam aliquet habitasse aenean phasellus bibendum dolor
                  cras fringilla sem praesent consectetuer tempor venenatis
                  donec. Turpis porta vel augue.
                </p>
                <p>
                  Aliquam sollicitudin consequat praesent eu diam. Aptent auctor
                  bibendum. Vulputate porttitor. Taciti tincidunt taciti vel
                  cubilia posuere nascetur ultrices ultrices lectus ridiculus
                  sodales pharetra felis eget nonummy justo mauris blandit.
                  consequat praesent eu diam. Aptent auctor bibendum. Vulputate
                  porttitor. Taciti tincidunt taciti vel cubilia posuere
                  nascetur.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*============== Innter Page Content End ==============*/}
    </div>
  );
};

export default Vission;
